<template>
  <div class="mb-3">
    <div class="row mb-3">
      <div class="col-md-5 col-12 mb-md-0 mb-2">
        <span class="fw-bolder tw-border-start me-3">儲值回報</span>
        <button
          class="tw-btn tw-btn-success me-3"
          @click="showModal('moneyReport')"
          v-if="$route.name !== 'P_ShoppingGold'"
        >
          新增儲值
        </button>
        <button
          class="tw-btn tw-btn-success"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 fw-bolder">
        <p>
          搜尋條件:
          <span class="badge bg-primary me-1 mb-1">
            審核狀態:
            {{ recordAdvancedSearchData.singleSelect.verifyStatus }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            時間區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <DataTable
      class="p-datatable-sm"
      :scrollHeight="`${scrollHeight}px`"
      :value="moneyReports"
      dataKey="id"
      :loading="dataTabelLoading"
      :rowHover="true"
      :paginator="true"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows="20"
      :rowsPerPageOptions="[20, 50, 100]"
      :scrollable="true"
      currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
      filterDisplay="row"
      stateStorage="local"
      stateKey="dt-state-moneyReport-local"
      sortField="createTime"
      :sortOrder="-1"
    >
      <!-- 金額 -->
      <Column
        field="money"
        header="金額"
        sortable
        style="min-width: 80px; max-width: 80px"
      >
      </Column>
      <!-- 帳號末五碼 -->
      <Column
        field="lastFiveDigit"
        header="帳號末五碼"
        sortable
        style="min-width: 150px; max-width: 150px"
      >
      </Column>
      <!-- 狀態 -->
      <Column
        field="verify"
        header="狀態"
        sortable
        style="min-width: 80px; max-width: 80px"
      >
        <template #body="{ data }">
          <p class="text-success" v-if="data.verify">通過</p>
          <p class="text-danger" v-else-if="data.verify === false">未通過</p>
          <div class="text-warning" v-else>
            <span v-if="$route.name === 'P_ShoppingGold'">
              <button
                class="tw-btn tw-btn-success me-2"
                @click="showModal('moneyVerify', true, data.id)"
              >
                確認
              </button>
              <button
                class="tw-btn tw-btn-danger"
                @click="showModal('moneyVerify', false, data.id)"
              >
                拒絕
              </button>
            </span>
            <span v-else>審核中</span>
          </div>
        </template>
      </Column>
      <!-- 建立時間 -->
      <Column
        field="createTime"
        header="建立時間"
        style="min-width: 200px; max-width: 200px"
        sortable
      >
      </Column>
      <!-- 匯款時間 -->
      <Column
        field="transferTime"
        header="匯款時間"
        style="min-width: 200px; max-width: 200px"
        sortable
      >
      </Column>
      <!-- 拒絕原因 -->
      <Column
        field="rejectReason"
        header="拒絕原因"
        class="text-break"
        style="min-width: 200px"
      >
      </Column>
      <!-- 備註 -->
      <Column
        field="note"
        header="備註"
        class="text-break"
        style="min-width: 200px"
      >
      </Column>
      <template #empty>
        <p class="text-center fw-bolder text-primary">
          目前尚未有任何資料 0..0
        </p>
      </template>
    </DataTable>
  </div>
  <!-- 新增儲值的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="moneyReportModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增儲值</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 金額 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="Money"
              placeholder="金額"
              min="0"
              v-model="moneyReportData.money"
              @input="
                moneyReportData.money = $methods.numberToFixed(
                  moneyReportData.money
                )
              "
            />
            <label for="Money">金額</label>
          </div>
          <!-- 帳號末五碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="LastFiveDigit"
              placeholder="帳號末五碼"
              v-model="moneyReportData.lastFiveDigit"
              maxlength="5"
            />
            <label for="LastFiveDigit">帳號末五碼</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating mb-3">
            <textarea
              class="form-control"
              placeholder="備註"
              maxlength="300"
              id="Note"
              v-model="moneyReportData.note"
            ></textarea>
            <label for="Note">備註(300字)</label>
          </div>
          <!-- 匯款時間 -->
          <p class="fw-bolder tw-border-start mb-2">匯款時間</p>
          <div class="form-floating">
            <input
              class="form-control"
              type="date"
              id="TransferTime"
              placeholder="匯款時間"
              v-model="moneyReportData.transferTime"
            />
            <label for="TransferTime" class="mb-2">匯款時間</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createMoneyReport"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 儲值金審核的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="moneyVerifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            購物金<template v-if="moneyVerifyData.verify">確認</template
            ><template v-else>拒絕</template>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="moneyVerifyData.verify"> 是否確認? </template>
          <template v-else>
            <p class="mb-2">是否拒絕? 確定並通知賣家</p>
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder="拒絕原因"
                id="MonetReject"
                style="height: 150px"
                maxlength="300"
                v-model="moneyVerifyData.rejectReason"
              ></textarea>
              <label for="MonetReject">拒絕原因(300字)</label>
            </div>
          </template>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="moneyVerify"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  components: {DataTable, Column},
  props: {
    participantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // modal
      moneyReportModal: {},
      moneyVerifyModal: {},
      advancedSearchModal: {},
      // data
      serverToken: '',
      scrollHeight: 0,
      dataTabelLoading: false,
      moneyReports: [],
      moneyReportData: {
        money: 0,
        lastFiveDigit: '',
        note: '',
        transferTime: ''
      },
      moneyVerifyData: {
        rejectReason: '',
        status: ''
      },
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        singleSelect: {
          verifyStatus: '全部',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
  },
  mounted() {
    this.createModals(['moneyReportModal','moneyVerifyModal', 'advancedSearchModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-moneyReport-local')
  },
  watch: {
    participantId: {
      handler(val, oldVal) {
        console.log(val, oldVal)
        this.computedD4TimeRange()
        if (val > 0) this.advancedSearch()
      },
      immediate: true
    },
    // 帳號末五碼格式
    'moneyReportData.lastFiveDigit'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.moneyReportData.lastFiveDigit = val.replace(/[^\d]/g, "");
      }
    },
  },
  methods: {
    showModal(status, item, data) {
      if (status === 'moneyReport') {
        // 新增儲值
        this.moneyReportData = {
          money: 0,
          lastFiveDigit: '',
          note: '',
          transferTime: ''
        }
        this.moneyReportModal.show()
      } else if (status === 'moneyVerify') {
        // 審核儲值金回報
        if (item) {
          this.moneyVerifyData = {
            id: data,
            verify: item
          }  
        }
        else {
          this.moneyVerifyData = {
            id: data,
            verify: item,
            rejectReason: '',
          }
        }
        this.moneyVerifyModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    initialization() {
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-moneyReport-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'verifyStatus',
          key: '審核狀態',
          value: [
            {
              id: 'A1',
              value: '全部'
            },
            {
              id: 'A2',
              value: '審核中'
            },
            {
              id: 'A3',
              value: '通過'
            },
            {
              id: 'A4',
              value: '不通過'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
    },
    // 取得 moneyReport
    advancedSearch(closeModal) {
      this.dataTabelLoading = true
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      const vm = this
      const getMoneyReportApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      let query = {"columns":[{"columnName":"id","siteObjectType":18}],"distinct":true,"group":{"groups":[{"column":{"columnName":"participantId","siteObjectType":18},"operator":{"operator":"=","type":1,"value":this.participantId}}],"operator":1},"siteObjectType":18,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 18
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 18
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 審核狀態
      console.log(this.advancedSearchData.singleSelect.verifyStatus)
      switch (this.advancedSearchData.singleSelect.verifyStatus) {
        case '審核中':
          query.group.groups.push({
            column: {
              columnName: 'verify',
              siteObjectType: 18
            },
            operator: {
              operator: "true",
              type: 4
            }
          })
          break;
        case '通過':
          query.group.groups.push({
            column: {
              columnName: 'verify',
              siteObjectType: 18
            },
            operator: {
              operator: "=",
              type: 1,
              value: true
            }
          })
          break;
        case '不通過':
          query.group.groups.push({
            column: {
              columnName: 'verify',
              siteObjectType: 18
            },
            operator: {
              operator: "=",
              type: 1,
              value: false
            }
          })
          break;
      }
      const data = [{
        query: query,
        methods: '{}'
      }]
      this.moneyReports = []
      if (closeModal) this.advancedSearchModal.hide()
      $.ajax({
        type: 'POST',
        async: true,
        url: getMoneyReportApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const moneyReports = res.data[0].objects
            vm.sortOutData(moneyReports)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理資料
    sortOutData(moneyReports) {
      setTimeout(() => {
        moneyReports.forEach(item => {
          item.createTime = this.$methods.moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')
          item.transferTime = this.$methods.moment(item.transferTime).format('YYYY-MM-DD HH:mm:ss')
        })
        this.moneyReports = moneyReports
        this.dataTabelLoading = false
      }, 1)
    },
    // 新增儲值
    createMoneyReport() {
      this.$methods.switchLoading('show')
      const vm = this
      const createMoneyReportApi = `${process.env.VUE_APP_API}/moneyReport/create`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      const data = [{
        participantId: this.participantId,
        money: this.moneyReportData.money ? this.moneyReportData.money : 0,
        lastFiveDigit: this.moneyReportData.lastFiveDigit ? this.moneyReportData.lastFiveDigit : null,
        note: this.moneyReportData.note,
        transferTime: this.moneyReportData.transferTime ? new Date(this.moneyReportData.transferTime) * 1 : null
      }]
      console.log(data)
      $.ajax({
        type: 'POST',
        async: true,
        url: createMoneyReportApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.advancedSearch()
            vm.moneyReportModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 儲值金審核
    moneyVerify() {
      if (!this.moneyVerifyData.verify && this.moneyVerifyData.rejectReason === '') return this.SweetAlert('other', '請輸入拒絕原因')
      this.$methods.switchLoading('show')
      const vm = this
      const moneyVerifyApi = `${process.env.VUE_APP_API}/moneyReport/verify`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      const data = [this.moneyVerifyData]
      $.ajax({
        type: 'PUT',
        async: true,
        url: moneyVerifyApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            // 如果為確認，刷新整個頁面
            if (vm.moneyVerifyData.verify) window.location.reload()
            else {
              vm.advancedSearch()
              vm.moneyVerifyModal.hide()
            }
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    }
  },
}
</script>