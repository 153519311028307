<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-5 col-12 mb-md-0 mb-2">
        <span class="fw-bolder tw-border-start me-3">購物金歷史紀錄</span>
        <button
          class="tw-btn tw-btn-success me-3"
          @click="showModal('updateWallet')"
          v-if="$route.name === 'P_ShoppingGold'"
        >
          新增購物金
        </button>
        <button
          class="tw-btn tw-btn-success"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 fw-bolder">
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            時間區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <DataTable
      class="p-datatable-sm"
      :scrollHeight="`${scrollHeight}px`"
      :value="walletHistories"
      dataKey="id"
      :loading="dataTabelLoading"
      :rowHover="true"
      :paginator="true"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rows="20"
      :rowsPerPageOptions="[20, 50, 100]"
      :scrollable="true"
      currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
      filterDisplay="row"
      stateStorage="local"
      stateKey="dt-state-walletHistory-local"
      sortField="createTime"
      :sortOrder="-1"
    >
      <!-- 款項來源 -->
      <Column
        field="sourceType"
        header="款項來源"
        sortable
        style="min-width: 80px; max-width: 80px"
      ></Column>
      <!-- 備註 -->
      <Column
        field="note"
        header="備註"
        sortable
        class="text-break"
        style="min-width: 100px"
      >
      </Column>
      <!-- 檢視 -->
      <Column field="" header="檢視" style="min-width: 80px; max-width: 80px">
        <template #body="{ data }">
          <button
            class="tw-btn tw-btn-success"
            @click="showModal('checkDetail', data)"
          >
            檢視
          </button>
        </template>
      </Column>
      <!-- 來源編號 -->
      <Column
        field="sourceId"
        header="來源編號"
        sortable
        style="min-width: 80px"
      ></Column>
      <!-- 購物金增減 -->
      <Column
        field="money"
        header="購物金增減"
        sortable
        style="min-width: 80px"
      ></Column>
      <!-- 購物金結餘 -->
      <Column
        field="currentMoney"
        header="購物金結餘"
        sortable
        style="min-width: 80px"
      ></Column>
      <!-- 建立者 -->
      <Column field="creator" header="建立者" sortable style="min-width: 100px">
        <template #body="{ data }">
          <p class="text-break">{{ data.creator }}</p>
        </template>
      </Column>
      <!-- 建立時間 -->
      <Column
        field="createTime"
        header="建立時間"
        sortable
        style="min-width: 200px"
      ></Column>
      <template #empty>
        <p class="text-center fw-bolder text-primary">
          目前尚未有任何資料 0..0
        </p>
      </template>
    </DataTable>
  </div>
  <!-- 顧客詳細資訊 > 購物金 > 新增購物金 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateWalletModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增購物金
            <a href="https://youtu.be/-S22idejHrM?si=Ya-iP_I53Ihqrtj-" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 金額調整 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="UpdateWallet"
              placeholder="金額調整"
              v-model="updateWalletData.adjustment"
              @change="
                updateWalletData.adjustment = $methods.numberToFixed(
                  updateWalletData.adjustment
                )
              "
            />
            <label for="UpdateWallet">金額調整</label>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註"
              id="BatchAddWalletNote"
              style="height: 150px"
              maxlength="300"
              v-model="updateWalletData.note"
            ></textarea>
            <label for="BatchAddWalletNote">備註(300字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateWallet"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 檢視的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkDetailModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">檢視</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="checkDeatailStatus === '賣家新增購物金紀錄'">
            {{ detail.value }}
          </div>
          <div v-else-if="checkDeatailStatus === '合併帳號'">
            {{ detail.value }}
          </div>
          <div v-else-if="checkDeatailStatus === '購物金回報'">
            <ul class="list fw-bolder">
              <li>
                審核結果:
                <span class="text-success" v-if="detail.source.verify"
                  >通過</span
                >
                <span class="text-danger" v-else>未通過</span>
              </li>
              <li v-if="!detail.source.verify">
                拒絕原因: {{ detail.source.rejectReason }}
              </li>
              <li>金額: {{ detail.source.money }}</li>
              <li>帳號末五碼: {{ detail.source.lastFiveDigit }}</li>
              <li>建立時間: {{ detail.source.createTime }}</li>
              <li>匯款時間: {{ detail.source.transferTime }}</li>
              <li>備註: {{ detail.source.note }}</li>
            </ul>
          </div>
          <div v-else-if="checkDeatailStatus === '帳單已被刪除'">
            {{ detail.value }}
          </div>
          <div v-else-if="checkDeatailStatus === '帳單折抵'">
            <div class="row">
              <div class="col-6">
                <ul class="list">
                  <li>帳單單號: {{ detail.id }}</li>
                  <li>建立時間: {{ detail.createTime }}</li>
                  <li>帳單備註: {{ detail.note }}</li>
                </ul>
              </div>
              <div class="col-6">
                <ul class="list">
                  <li>
                    折抵狀態:
                    <span class="text-success" v-if="detail.walletUsed"
                      >已折</span
                    >
                    <span class="text-danger" v-else>未折</span>
                  </li>
                  <li>折抵金額: {{ detail.walletUseAmount }}</li>
                  <li>
                    付款狀態:
                    <span class="text-success" v-if="detail.paid">已付</span>
                    <span class="text-danger" v-else>未付</span>
                  </li>
                  <li>
                    包貨狀態:
                    <span class="text-success" v-if="detail.packaged"
                      >已包</span
                    >
                    <span class="text-danger" v-else>未包</span>
                  </li>
                  <li>
                    寄送狀態:
                    <span class="text-success" v-if="detail.shipped">已寄</span>
                    <span class="text-danger" v-else>未寄</span>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="row"
              v-for="billBodie in billBodies"
              :key="billBodie.id"
            >
              <hr />
              <div class="col-lg-1 mb-2">
                <Image
                  class="table-img"
                  :imageHash="billBodie.imageHash"
                  size="s"
                  v-if="billBodie.imageHash"
                  :alt="billBodie.imageHash"
                ></Image>
                <img
                  class="table-img"
                  src="@/assets/other-images/noImg.png"
                  alt=""
                  v-else
                />
              </div>
              <div class="col-lg-6">
                <ul class="list">
                  <li>商品名稱: {{ billBodie.name }}</li>
                  <li>商品款式: {{ billBodie.style }}</li>
                </ul>
              </div>
              <div class="col-lg-5">
                <ul class="list">
                  <li>帳單備註: {{ billBodie.note }}</li>
                  <li>
                    數量: {{ billBodie.quantity }} × 單價:{{ billBodie.price }}
                    = 總價:
                    {{
                      $methods.numberToFixed(
                        billBodie.quantity * billBodie.price
                      )
                    }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch_WH"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch_WH"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime_WH"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime_WH" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime_WH"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime_WH" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
  components: {DataTable, Column},
  props: {
    participantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // modal
      checkDetailModal: {},
      updateWalletModal: {},
      advancedSearchModal: {},
      // data
      serverToken: '',
      scrollHeight: 0,
      dataTabelLoading: false,
      walletHistories: [],
      // 顧客詳細資訊 > 購物金 > 新增購物金
      updateWalletData: {
        adjustment: 0,
        note: '',
      },
      // 檢視 modal 內用來判斷要顯示哪種資訊
      checkDeatailStatus: '',
      detail: {},
      billBodies: [],
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
      },
      recordAdvancedSearchData: {},
    }
  },
  created() {
    this.initialization()
  },
  mounted() {
    this.createModals(['checkDetailModal','updateWalletModal', 'advancedSearchModal'])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-walletHistory-local')
  },
  watch: {
    participantId: {
      handler(val, oldVal) {
        console.log(val, oldVal)
        this.computedD4TimeRange()
        if (val > 0) this.advancedSearch()
      },
      immediate: true
    },
  },
  methods: {
    showModal(status, item) {
      if (status === 'checkDetail') {
        // 檢視
        console.log(item)
        this.checkDeatailStatus = item.status
        this.detail = {}
        this.billBodies = []
        if (item.status === '賣家新增購物金紀錄') {
          this.detail.value = '賣家新增購物金紀錄'
        } else if (item.status === '合併帳號') {
          this.detail.value = '合併帳號'
        } else if (item.status === '購物金回報') {
          this.detail = item
        } else if (item.status === '帳單已被刪除') {
          this.detail.value = '帳單已被刪除'
        } else if (item.status === '帳單折抵') {
          this.getBillInfo(item)
        }
        this.checkDetailModal.show()
      } else if (status === 'updateWallet') {
        // 顧客詳細資訊 > 購物金 > 新增購物金
        this.updateWalletData = {
          adjustment: 0,
          note: ''
        }
        this.updateWalletModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    initialization() {
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-walletHistory-local')
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
    },
    // 取得 walletHistories
    advancedSearch(closeModal) {
      this.dataTabelLoading = true
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      const vm = this
      const getWalletHistoriesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      let query = {"columns":[{"columnName":"id","siteObjectType":19}],"distinct":true,"group":{"groups":[{"column":{"columnName":"ownerId","siteObjectType":19},"operator":{"operator":"=","type":1,"value":this.participantId}}],"operator":1},"siteObjectType":19,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 19
            },
            operator: {
              operator: ">=",
              type: 1,
              value:  {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 19
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      const data = [{
        query: query,
        methods: '{getSource,getCreator{getUser}}'
      }]
      if (closeModal) this.advancedSearchModal.hide()
      $.ajax({
        type: 'POST',
        async: true,
        url: getWalletHistoriesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const walletHistories = res.data[0].objects
            vm.sortOutData(walletHistories)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理資料
    sortOutData(walletHistories) {
      console.log(walletHistories)
      setTimeout(() => {
        this.walletHistories = []
        walletHistories.forEach(item => {
          let object = {
            sourceType: '',
            sourceId: 0,
            note: item.note,
            currentMoney: item.balance,
            money: item.money,
            creator: item.creator ? item.creator.user.name : '合併帳號',
            createTime: this.$methods.moment(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
            status: null,
            source: null,
          }
          if (item.sourceType === 'BILLHEADER') {
            // 來源 > 帳單
            if (!item.source) {
              object.status = '帳單已被刪除'
              object.sourceType = '帳單已被刪除'
            } else {
              object.status = '帳單折抵'
              object.sourceType = '帳單折抵'
              object.sourceId = item.sourceId
              object.source = item.source
            }
          } else if (item.sourceType === 'MONEYREPORT') {
            // 來源 > 購物金回報
            object.status = '購物金回報'
            object.sourceType = '購物金回報'
            object.sourceId = item.sourceId
            item.source.createTime = this.$methods.moment(item.source.createTime).format('YYYY-MM-DD HH:mm:ss')
            item.source.transferTime = this.$methods.moment(item.source.transferTime).format('YYYY-MM-DD HH:mm:ss')
            object.source = item.source
          } else if (!item.sourceType) {
            if (item.creator) {
              // 來源 > 賣家幫忙新增購物金
              object.status = '賣家新增購物金紀錄'
              object.sourceType = '賣家新增購物金紀錄'
            } else {
              // 來源 > 合併帳號
              object.status = '合併帳號'
              object.sourceType = '合併帳號'
            }
          }
          this.walletHistories.push(object)
        });
        this.dataTabelLoading = false
      }, 1)
    },
    // 取得帳單資訊
    getBillInfo(source) {
      this.$methods.switchLoading('show')
      const vm = this
      const getBillInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      const data = [{
        type: 27,
        ids: [source.sourceId],
        methods: '{getBillBodies{getMerchOrder{getMerchandiseStyles,getMerchandise{getMerchandisePictureLinks}}}}'
      }]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billInfo = res.data[0].objects[0]
            vm.detail = {
              id: billInfo.id,
              billBodies: billInfo.billBodies,
              createTime: vm.$methods.moment(billInfo.createTime).format('YYYY-MM-DD HH:mm:ss'),
              note: billInfo.note,
              packaged: billInfo.packaged,
              paid: billInfo.paid,
              shipped: billInfo.shipped,
              walletUseAmount: billInfo.walletUseAmount,
              walletUsed: billInfo.walletUsed,
            }
            billInfo.billBodies.forEach(item => {
              let style = []
              let imageHash = ''
              item.merchOrder.merchandiseStyles.forEach(merchandiseStyle => style.push(merchandiseStyle.name))
              item.merchOrder.merchandise.merchandisePictureLinks.some(merchandisePicture => {
                if(merchandisePicture.front) imageHash = merchandisePicture.imageHash
              })
              let obj = {
                id: item.id,
                imageHash: imageHash,
                name: item.merchOrder.merchandise.name,
                style: style.join(),
                note: item.merchOrder.note !== '' ? item.merchOrder.note : '無',
                quantity: item.quantity,
                price: item.price
              }
              vm.billBodies.push(obj)
            })
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 新增購物金
    updateWallet() {
      if (this.updateWalletData.adjustment === '') return this.SweetAlert('other', '調整金額不為空值')
      if (this.updateWalletData.adjustment === 0) return this.SweetAlert('other', '調整金額不為零')
      this.$methods.switchLoading('show')
      const vm = this
      const updateWalletApi = `${process.env.VUE_APP_API}/participant/updateWallet`
      const header = {
        authorization: this.$methods.getCookie('serverToken'),
      }
      const data = [{
        id: this.participantId,
        adjustment: this.updateWalletData.adjustment,
        note: this.updateWalletData.note
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateWalletApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.advancedSearch()
            vm.SweetAlert(res.code, res.message)
            vm.selectItems = []
            vm.updateWalletModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
  },
}
</script>